import "./Landing.css";

export function Landing() {
  return (
    <div className="main-message">
      <div className="container-fluid p-5">
        <h2 className="m-5">
          Use your time working from home to create meaningful connections
        </h2>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col display-4">How it works</div>
        </div>

        <div className="row justify-content-around mt-5 mb-5">
          <div className="col-12 col-md-3 card">
            <div>
              <span className="badge badge-pill badge-info mb-4">Step 1</span>
            </div>
            <span className="display-4">🧔 📸</span>
            <p>Create your profile</p>
          </div>
          <div className="col-12 col-md-3 card">
            <div>
              <span className="badge badge-pill badge-info mb-4">Step 2</span>
            </div>
            <span className="display-4">➕ 📆</span>
            <p>Connect you calendar</p>
          </div>
          <div className="col-12 col-md-3 card">
            <div>
              <span className="badge badge-pill badge-info mb-4">Step 3</span>
            </div>
            <span className="display-4">😁 💬 </span>
            <p>Share your link and schedule conversations</p>
          </div>
        </div>
      </div>
    </div>
  );
}
