import { Helmet } from "react-helmet";

import { Footer } from "./Footer";
import productOg from "../assets/product-og.png";
import logo from "../assets/me.png";
import donut from "../assets/donut.png";
import "./Profile.css";
import "../App.css";

export function Profile() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Lunch with me - use your working from home time to create meaningful
          connections
        </title>
        <meta
          property="og:title"
          content="Lunch with me - use your working from home time to create meaningful
          connections"
        />
        <meta property="og:url" content="http://www.lunchwith.me" />
        <meta property="og:image" content={productOg} />

        <meta
          property="og:description"
          content="Create meaningful connections over lunch"
        />
      </Helmet>

      <div className="Profile-banner-desktop h5 p-3 text-light">
        🍥 Use your time working from home to create meaningful connections -
        over a digital lunch 🍥
      </div>
      <div className="Profile-banner-mobile h5 p-3 text-light">
        Working from home? Digital lunch?
      </div>

      <div className="Profile-container container">
        <div className="row">
          <div className="col-6 col-md-12 mt-5 align-self-center">
            <img src={logo} className="Profile-logo mt-5" alt="logo" />
          </div>
          <div className="col-6 col-md-12 align-self-center mt-5 mt-md-0">
            <small class="form-text text-muted mt-5 text-left text-md-center d-none d-md-block">
              Hey 👋
            </small>
            <small class="form-text text-muted text-left text-md-center text-small d-none d-md-block">
              I'm Natascha, let's eat some 🥯 over a great conversation
            </small>
            <p class="form-text text-muted mt-5 text-left text-md-center d-block d-md-none">
              Hey 👋
            </p>
            <p class="form-text text-muted text-left text-md-center text-small d-block d-md-none">
              I'm Natascha, let's eat some 🥯 over a great conversation
            </p>
          </div>

          <div
            className="calendly-inline-widget Profile-calendar mt-5"
            data-url="https://calendly.com/lunch-with-natascha/30min"
          ></div>
          <div className="col mb-5">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 text-md-right">
                <a href="https://lunchwith.me" target="_blank" rel="noreferrer">
                  <img
                    className="Profile-donut"
                    src={donut}
                    alt="Donuts everywhere"
                  />
                </a>
              </div>
              <div className="col-12 col-md-7 text-md-left mr-md-5">
                <a href="https://lunchwith.me" target="_blank" rel="noreferrer">
                  <p className="h2 font-weight-light mr-md-5">
                    You want this too? Sign Up today!
                  </p>
                </a>
                <p className="h3 font-weight-light mr-md-5">
                  Use your time working from home to create meaningful
                  connections
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-5">
          <a
            href="https://www.lunchwith.me"
            target="_blank"
            rel="noreferrer"
            className="btn btn-lg btn-info p-3 mb-5"
          >
            Start building connections - One lunch at a time
          </a>
        </div>
        <div className="mb-5 text-light">h</div>
      </div>
      <Footer />
    </div>
  );
}
