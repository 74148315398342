import "./SignUp.css";

export function SignUp() {
  return (
    <div className="Signup-bg">
      <div className="container pt-5">
        <a
          href="/"
          type="button"
          className="Signup-btn-circle btn btn-link btn-lg"
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            className="bi bi-chevron-left"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </a>
        <div className="row align-content-center">
          <div className="col-12 col-md-8 offset-md-2 mt-5">
            <div className="card">
              <h1 className="text-center mt-5">Sign Up</h1>
              <h3 className="font-weight-light text-center">
                start building connections
              </h3>
              <form className="p-5">
                <div className="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                  <small id="emailHelp" class="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>
                {/* <div className="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" for="exampleCheck1">
                    Check me out
                  </label>
                </div> */}
                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="btn btn-info btn-lg disabled"
                  >
                    Continue
                  </button>
                  <small class="form-text text-muted">Coming soon</small>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
