import "../App.css";

export function Footer() {
  return (
    <div className="container-fluid footer text-white pt-5 pb-5">
      <div className="row justify-content-around">
        <div className="col-12 col-md-4 text-left">
          <p>
            <a href="https://www.lunchwith.me" className="contact-link">
              <small>home</small>
            </a>
          </p>

          <p>
            <small>about</small>
            <span className="badge badge-info ml-3 footer-label">
              Coming soon
            </span>
          </p>
          <p>
            <small>upcoming features</small>
            <span className="badge badge-info ml-3 footer-label">
              Coming soon
            </span>
          </p>
        </div>
        <div className="col-12 col-md-4 text-left">
          <div>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-envelope-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
              />
            </svg>
            <small className="ml-3">
              <a
                className="contact-link"
                href="mailto:natascha.lunchwithme@gmail.com"
              >
                contact LunchWithMe
              </a>
            </small>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4 footer-greet">
        with
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-suit-heart-fill pt-2"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
        </svg>
        from earth
      </div>
    </div>
  );
}
