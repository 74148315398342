import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";

import "./App.css";
import { Home } from "./components/Home";
import { SignUp } from "./components/SignUp";
import { Profile } from "./components/Profile";

var firebaseConfig = {
  apiKey: "AIzaSyC0YNlpWcA7IlcJqxwKdMTPfEHVD1iCZ9Y",
  authDomain: "lunchwithme-3cd06.firebaseapp.com",
  databaseURL: "https://lunchwithme-3cd06.firebaseio.com",
  projectId: "lunchwithme-3cd06",
  storageBucket: "lunchwithme-3cd06.appspot.com",
  messagingSenderId: "437750556998",
  appId: "1:437750556998:web:1c0c4e0693d870d3101d47",
  measurementId: "G-1FXW451945",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/privacy-policy">
            <SignUp />
          </Route>
          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/amazing/:username">
            <Profile />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
