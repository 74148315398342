import "./Product.css";
import product from "../assets/product.png";

export function Product() {
  return (
    <div className="col mt-5 pt-5 mb-5">
      <div className="row pt-5 mt-5 justify-content-center">
        <div className="col-12 col-md-7 text-right">
          <img
            className="product-image"
            src={product}
            alt="Lunch with me on desktop"
          />
        </div>
        <div className="col-12 col-md-5 text-left p-5">
          <h3 className="display-4">Set it up today!</h3>
          <a href="/signup" className="btn btn-info btn-lg my-5">
            Sign up and start building connections
          </a>
        </div>
      </div>
    </div>
  );
}
