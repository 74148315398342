import "./Header.css";

export function Header() {
  return (
    <div className="container-fluid">
      <div className="row main align-items-center text-left position-relative">
        <div className="col-12 col-md-4 pl-5 main-intro">
          <p className="badge badge-warning text-uppercase">online edition</p>
          <h1 className="display-1">lunch with me</h1>
          <div className="display-3">🍜 🍩 🥗</div>
        </div>
        <div className="col-12 col-md-8">
          <div className="avatars-bg"></div>
        </div>
      </div>
    </div>
  );
}
