import { Helmet } from "react-helmet";

import "../App.css";
import { Navigation } from "./Navigation";
import { Header } from "./Header";
import { Landing } from "./Landing";
import { Product } from "./Product";
import { Footer } from "./Footer";
import productOg from "../assets/product-og.png";

export function Home() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Lunch with me - use your working from home time to create meaningful
          connections
        </title>
        <meta
          property="og:title"
          content="Lunch with me - use your working from home time to create meaningful
          connections"
        />
        <meta property="og:url" content="http://www.lunchwith.me" />
        <meta property="og:image" content={productOg} />

        <meta
          property="og:description"
          content="Create meaningful connections over lunch"
        />
      </Helmet>
      <Navigation />
      <Header />
      <Landing />
      <Product />
      <Footer />
    </div>
  );
}
