import "./Navigation.css";

export function Navigation() {
  return (
    <div className="">
      <div className="navbar navbar-expand-lg navbar-light bg-white justify-content-end">
        <a className="navbar-brand mr-5" href="/">
          About
        </a>

        {/* <form class="form-inline my-2 my-lg-0"> */}
        <a
          href="/signup"
          className="btn btn-info btn-lg my-2 my-sm-0 nav-item text-right mr-md-4"
        >
          Signup
        </a>
      </div>
      <div className="Navigation-divider"></div>
    </div>
  );
}
